import React from 'react';
import StandardLayout from '../../layouts/standard';
import pdf3 from '../../assets/elegant-series.pdf';

export default function ElegantSeries(props) {
    return <StandardLayout title="Elegant Series">
        <div className="uk-section uk-container uk-margin">
        <embed src={pdf3} width="100%" style={{height: "80vh"}} type="application/pdf"/>
        </div>
    </StandardLayout>
}